import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const TextBlock = () => {
    const data = useStaticQuery(graphql`
    {
      allWpPage (filter: {slug: {eq: "about-chan"}}){
        nodes {
          content
        }
      }
    }
  `)

  const content = data.allWpPage.nodes.map(({content}) => (<div dangerouslySetInnerHTML={{ __html: content }} />))
    return(
                
        <div>{content}</div>

    )
}

export default TextBlock