import React from 'react';
import Slider from "./sliderprod";

// Images imports 

const ProductionBlock = () => {

    return (
        <>
        <section class="section-group">
          <div id="proizvodstvo"></div>
          <div class="production">
            <div class="container">
              <div class="production__wrap">
                <div class="production__side production__info">
                  <p class="section-title section-title--green production__title">НАШЕ <br/>ПРОИЗВО-<br/>ДСТВО</p>
                  <p class="production__desc">С учетом высоких требований к качеству нашей продукции, как заказчиков, так и нас самих, мы оснащены всем необходимым современным оборудованием. У нас работают высококвалифицированные специалисты по работе с металлом.</p><span class="production__subtitle">МЫ СТАРАЕМСЯ ДЕЛАТЬ ЛУЧШИЕ ЧАНЫ В РОССИИ</span>
                </div>
                <div class="production-slider-wrap">        
                  <div class="swiper-container production-slider">
                    <div class="swiper-wrapper">
                      <Slider />
                      {/* <div class="swiper-slide">
                        <div class="gallery__side production__block">
                          <div class="gallery__row gallery__row--flex">
                            <div class="gallery-img gallery-img--flex production__img"><img class="gallery-img__pic" src="images/content/prod/svarka.jpg" alt="" />
                              <div class="production__img-info"><span class="production__img-title">Сварка конструкции</span>
                                <p class="production__img-desc">Сварка каркаса и деталей банного чана - один из ответственных этапов производства. При нагревании металл расширяется и каждый сварной шов должен гарантированно выдержать свою нагрузку. </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div class="swiper-slide">
                        <div class="gallery__side production__block">
                          <div class="gallery__row gallery__row--flex">
                            <div class="gallery-img gallery-img--flex production__img"><img class="gallery-img__pic" src="images/content/prod/raskroika.jpg" alt="" />
                              <div class="production__img-info"><span class="production__img-title">Раскройка металла</span>
                                <p class="production__img-desc">Раскройка металла осуществляется на современном лазерном оборудовании, исключая дефекты отдельных деталей, гарантируя надежность сваривания деталей между собой.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div class="swiper-slide">
                        <div class="gallery__side production__block">
                          <div class="gallery__row gallery__row--flex">
                            <div class="gallery-img gallery-img--flex production__img"><img class="gallery-img__pic" src="images/content/prod/shlifovka.jpg" alt="" />
                              <div class="production__img-info"><span class="production__img-title">Шлифовка, подготовка под покраску</span>
                                <p class="production__img-desc">Банный чан создан для удовольствия, поэтому каждый миллиметр должен быть тщательнейшим образом отшлифован.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* <div class="slider-nav production-nav">
                      <div class="production-button-prev swiper-button-prev slider-nav__btn slider-nav__btn-prev">
                        <svg сlass="slider-nav__icon" width="48" fill="#A1A2A3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                          <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                                 405.606,308.713 476.213,238.106 "></polygon>
                        </svg>
                      </div>
                      <div class="production-pagination swiper-pagination slider-pagination"></div>
                      <div class="production-button-next swiper-button-next slider-nav__btn slider-nav__btn-next">
                        <svg сlass="slider-nav__icon" width="48" fill="#A1A2A3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                          <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                                 405.606,308.713 476.213,238.106 "></polygon>
                        </svg>
                      </div>
                    </div> */}
                  </div>

                  {/* <div class="swiper-container production-tumbs">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <div class="gallery-img gallery-img--small production-tumbs__item">
                          <div class="gallery-img__inner"><span class="production__plus"></span></div><img class="gallery-img__pic" src="images/content/prod/svarka.jpg" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="gallery-img gallery-img--small production-tumbs__item">
                          <div class="gallery-img__inner"><span class="production__plus"></span></div><img class="gallery-img__pic" src="images/content/prod/raskroika.jpg" alt="" />
                        </div>
                      </div>
                      <div class="swiper-slide">
                        <div class="gallery-img gallery-img--small production-tumbs__item">
                          <div class="gallery-img__inner"><span class="production__plus"></span></div><img class="gallery-img__pic" src="images/content/prod/shlifovka.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
          </section>
      </>
    )
}

export default ProductionBlock