import React from 'react';
import PromoBlock from './promoblock';


const Promo = () => {
    return (
        <PromoBlock />
    )
}

export default Promo