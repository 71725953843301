import React from 'react';
import ContactsBlock from './contactsblock';


const Contacts = () => {
    return (
        <ContactsBlock />
    )
}

export default Contacts