import React from 'react';
import {Link} from 'gatsby'

import Text from "./textblock"
import CatalogSlider from "./catalogslider"

// Images imports 
// import ChanOne from "../../../images/content/chan3.png"

const CatalogBlock = () => {
    return (
        <>
        <section class="section-group">
        <div id="catalog"></div>
          <div class="catalog">
            <div class="container">
              <h2 class="section-title section-title--green catalog__title">КАКОЙ ИМЕННО ЧАН <br/>ВАМ ЛУЧШЕ ПРИОБРЕСТИ</h2>
              <p class="catalog__desc">Позвоните нашему менеджеру по телефону  <a href="tel:79667076676" class="catalog__phone">+ 7 966 707 66 76</a> и он поможет подобрать чан, который Вам подойдет. <br/><br/>Или познакомьтесь с характеристиками наших лучших чанов ниже и выберите его сами.</p>
              
              {/* ====== */}
              
                <CatalogSlider />

              {/* ============ */}
              <button class="catalog__all" type="button"><Link to="/catalog" aria-label="Link to catalog"><span class="catalog__all-text">В КАТАЛОГ</span></Link></button>
              <Text />
            </div>
           
          </div>
        </section>
      </>
    )
}

export default CatalogBlock