import React, { Component } from "react";
import Slider from "react-slick";

// Images imports 
import TravMaxImg from "../../../images/content/travmax-review.png"
import AngelinaImg from "../../../images/content/reviews-img.png"

export default class ReviewSlider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      customPaging: i => (
            <div class="slider-nav">
                <div class="reviews-button-prev swiper-button-prev slider-nav__btn slider-nav__btn-prev">
                    {/* <svg сlass="slider-nav__icon" width="48" fill="rgba(170, 170, 170, 0.83)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                    <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                         405.606,308.713 476.213,238.106 "></polygon>
                    </svg> */}
                </div>
                <div class="reviews-pagination swiper-pagination slider-pagination">{'0'+ (i + 1)}</div>
                <div class="reviews-button-next swiper-button-next slider-nav__btn slider-nav__btn-next">
                    {/* <svg сlass="slider-nav__icon" width="48" fill="rgba(170, 170, 170, 0.83)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                    <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                         405.606,308.713 476.213,238.106 "></polygon>
                    </svg> */}
                </div>
            </div>
      )
    };
    return (
        <div class="reviews">
        <div class="container">
          <p class="reviews__title">А ЧТО ГОВОРЯТ ЛЮДИ?</p>
        <Slider {...settings}>
            {/* SLIDER ONE */}
            <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>
            <div class="reviews-prev">
                <div class="reviews-prev__label">

                </div>
                    <div class="reviews-prev__info">
                        <div class="reviews-prev__header">
                            <span class="reviews-prev__date">09.04.2021</span><span class="reviews-prev__name">TravelMaximum</span>
                        </div>
                        <p class="reviews-prev__desc">Классные чаны и грили из металла делает Chanone. <span role="img" aria-label= "thumbUp">👍</span>Приятно понежится в такой теплой бане, когда снаружи -10 <span role="img" aria-label= "LOL">😁</span>. Очень рекомендуем!</p><a class="reviews-prev__all" href="https://www.instagram.com/p/CNc0RqNCofV/">Посмотреть отзыв</a>
                    </div>

                    <div class="reviews-prev__img">
                        <img class="reviews-prev__img-pic" src={TravMaxImg} alt="" />
                    </div>
            </div>
            </div>

          <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>
          <div class="reviews-prev">
                    <div class="reviews-prev__label">
                      {/* <svg class="reviews-prev__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g>
                          <g></g>
                          <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016                                    c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992                                    c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056                                    c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152                                    c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792                                    c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44                                    C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568                                    C480.224,136.96,497.728,118.496,512,97.248z"></path>
                        </g>
                      </svg> */}
                    </div>
                    <div class="reviews-prev__info">
                      <div class="reviews-prev__header"><span class="reviews-prev__date">16.08.2020</span><span class="reviews-prev__name">Ангелина</span></div>
                      <p class="reviews-prev__desc">Наш банный чан одно из лучших приобретений за последнее время. Собираемся с друзьями чаще, и отлично проводим время нежась в приятной купели. А какое наслаждение находиться в теплой воде зимой! Вокруг хвойные деревья, легкий аромат дыма и отличный релакс после процедуры. Всем советую!</p>
                    </div>
                    <div class="reviews-prev__img"><img class="reviews-prev__img-pic" src={AngelinaImg} alt="" /></div>
                  </div>
          </div>
        </Slider>
      </div>
      </div>
    );
  }
}