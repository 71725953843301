import React from 'react';

import MainOfferForm from "../../Modules/MainOfferForm"

// Images imports 
import OfferImg1 from "../../../images/content/offer-img.png"
import OfferImg2 from "../../../images/content/offer-img2.png"

const OfferBlock = () => {
    return (
        <>
        <div className="offer">
          <div className="container">
            <div className="offer__block">
              <div className="offer__info"><span className="offer__title">ПОЛУЧИТЬ СПЕЦПРЕДЛОЖЕНИЕ</span>
                <p className="offer__desc">Мы сотрудничаем с дизайнерами, ландшафтными специалистами и открыты любым идеям, не противоречащим законам физики, термодинамики и Кодексу РФ. </p><span className="offer__subtitle">Кстати, до мая месяца у нас отличные скидки для дизайнеров и отелей!</span>
                
                <MainOfferForm />
                
                {/* <form className="offer__form form" action="offer.php" method="post">
                  <div className="form__group">
                    <input className="input-field" type="text" name="email" placeholder="E-mail:" required />
                    <input className="input-field" type="tel" name="phone" placeholder="Телефон:" required />
                  </div>
                  <button className="btn-chan form__send-btn" type="submit">Подробнее</button>
                </form> */}

              </div>
              <div className="offer__img"><img className="offer__img-pic" src={OfferImg1} alt="" /></div>
              <div className="offer__img-mobile"><img className="offer__img-pic" src={OfferImg2} alt="" /><span className="offer__img-title">А ЕЩЕ МЫ ДЕЛАЕМ КРУТЫЕ ДОМА ИЗ СРУБА</span></div>
            </div>
          </div>
        </div>
      </>
    )
}

export default OfferBlock