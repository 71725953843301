import React, { Component } from "react";
import Slider from "react-slick";

export default class ProjectsBlock extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: i => (
        <div className="projects-slider__nav">
                  <div className="projects-button-prev swiper-button-prev slider-nav__btn-chan slider-nav__btn-chan-prev">
                    {/* <svg сlass="slider-nav__icon projects-slider__nav-icon" width="48" fill="#ffffff" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                      <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                             405.606,308.713 476.213,238.106 "></polygon>
                    </svg> */}
                  </div>
                  <div className="projects-pagination swiper-pagination slider-pagination">{'0'+ (i + 1)}</div>
                  <div className="projects-button-next swiper-button-next slider-nav__btn-chan slider-nav__btn-chan-next">
                    {/* <svg сlass="slider-nav__icon projects-slider__nav-icon" width="48" fill="#ffffff" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                      <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                             405.606,308.713 476.213,238.106 "></polygon>
                    </svg> */}
                  </div>
                </div>
  )
    };
    return (
      <div className="projects">
          <div className="container">
            <div className="projects__wrap">
              <div className="projects-slider swiper-container">
        <Slider {...settings}>
          <div>
          <div className="projects-item projects-house-one" >
                      <div className="projects-item__info projects-item__info--width"><span className="projects-item__title">ДОМ "СКАЗКА"</span>
                        <p className="projects-item__desc">220м общей жилой площади. Выполнен из сухого бревна диаметром 600мм</p><a className="btn-chan btn-chan--orange projects-item__more" href="/catalog/house-skazka">Узнать больше</a>
                      </div>
                    </div>
          </div>
          <div>
          <div className="projects-item projects-house-two">
                      <div className="projects-item__info projects-item__info--width"><span className="projects-item__title">БАНЯ "ЛОГАН"</span>
                        <p className="projects-item__desc">54м общей жилой площади. Выполнен из сухого бревна диаметром 600мм</p><a className="btn-chan btn-chan--orange projects-item__more" href="/catalog/house-skazka">Узнать больше</a>
                      </div>
                    </div>
          </div>
          <div>
          <div className="projects-item projects-house-three">
                      <div className="projects-item__info projects-item__info--width"><span className="projects-item__title">НАВЕСЫ</span>
                        <p className="projects-item__desc">Оригинальные навесы для машины из бревна диаметром до 600мм. </p><a className="btn-chan btn-chan--orange projects-item__more" href="/catalog/house-skazka">Узнать больше</a>
                      </div>
                    </div>
          </div>
        </Slider>
      </div>
      </div>
      </div>
      </div>
    );
  }
}