import React from "react";
import Slider from "react-slick";

import "../../../../node_modules/slick-carousel/slick/slick.css"

// Images imports 
// import ChanOne from "../../../images/content/chan3.png"

import { useStaticQuery, graphql, Link } from "gatsby"
// import {Container, Row, Col} from "react-bootstrap"


const ChansCategory = () => {
  const data = useStaticQuery(graphql`
  {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "chans"}}}}}) {
        nodes {
          productTitles {
            productName
            productSubname
            productimage {
              sourceUrl
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          title
          content
          slug
          catalogDescriptions {
            catalogDescription
          }
        }
      }
  }
  `)

  const settings = {
          dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            }
          ],
          customPaging: i => (
                        <div class="slider-nav">
                            <div class="reviews-button-prev swiper-button-prev slider-nav__btn slider-nav__btn-prev">
                                {/* <svg сlass="slider-nav__icon" width="48" fill="rgba(170, 170, 170, 0.83)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                                <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                         405.606,308.713 476.213,238.106 "></polygon>
                                </svg> */}
                            </div>
                            <div class="equipment-pagination swiper-pagination slider-pagination">{'0'+ (i + 1)}</div>
                            <div class="reviews-button-next swiper-button-next slider-nav__btn slider-nav__btn-next">
                                {/* <svg сlass="slider-nav__icon" width="48" fill="rgba(170, 170, 170, 0.83)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                                <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                         405.606,308.713 476.213,238.106 "></polygon>
                                </svg> */}
                            </div>
                        </div>
                  )
  }
  const slide = data.allWpPost.nodes.map(({content, slug, productTitles, catalogDescriptions}) => (
    
        <div class="catalog-prev">
            <div class="catalog-prev__header">
                <span class="catalog-prev__category">{productTitles.productName}</span>
                <p class="catalog-prev__name"dangerouslySetInnerHTML= {{__html: productTitles.productSubname}} />
                <div class="catalog-prev__img"><img class="catalog-prev__img-pic" src={productTitles.productimage.localFile.childImageSharp.original.src} alt="" /></div>
            </div>
            <div class="catalog-prev__main">
                <div class="catalog-prev__description" dangerouslySetInnerHTML= {{__html: catalogDescriptions.catalogDescription}} />
                <Link to={`/catalog/${slug}`} className="btn-chan btn-chan--orange catalog-prev__more">ПОДРОБНЕЕ</Link>
            </div>
        </div>
))

    return (
              <Slider {...settings}>
                {slide}
              </Slider>
)
}

export default ChansCategory;
