import React from 'react';
import ProcessBlock from './processblock';


const Process = () => {
    return (
        <ProcessBlock />
    )
}

export default Process