import React from 'react';

import MainForm from "../../Modules/MainForm"

// Images imports 
import FeedbackImg from "../../../images/content/feedback-img.png"

const ContactsBlock = () => {
    return (
        <>
        <div id="contact"></div>
        <div class="offer feedback">
          <div class="container">
            <div class="offer__block feedback__block">
              <div class="offer__info feedback__info"><span class="offer__title feedback__title">Есть вопросы?</span><span class="offer__subtitle feedback__subtitle">Заполните формы и мы срадостью ответим на них.</span>
                <p class="offer__desc feedback__desc">Или позвоните по телефону <a href="tel:79667076676" class="feedback__phone">+ 7 966 707 66 76</a></p>
                
                <MainForm />

                {/* <form class="offer__form form feedback__form" action="mail.php" method="post">
                  <div class="form__row">
                    <div class="form__group">
                      <input class="input-field feedback__form-field" type="text" name="name" placeholder="Имя:" required />
                      <input class="input-field feedback__form-field" type="email" name="email" placeholder="E-mail:" required />
                      <input class="input-field feedback__form-field" type="tel" name="phone" pattern="^((8|+7)[- ]?)?((?d{3})?[- ]?)?[d- ]{7,10}$" placeholder="Телефон:" required />
                    </div>
                    <div class="form__group">
                      <textarea class="input-field input-textarea" placeholder="Задайте вопрос:" name="msg" required></textarea>
                    </div>
                  </div>
                  <button class="btn-chan form__send-btn feedback__send-btn" type="submit">Отправить</button>
                </form> */}

              </div>
              <div class="offer__img feedback__img"><img class="offer__img-pic" src={FeedbackImg} alt="" /></div>
            </div>
          </div>
        </div>
      </>
    )
}

export default ContactsBlock