import React, {useState} from 'react';
import {Modal} from 'react-bootstrap'

export default function VideoModal() {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <button class="btn-chan overview__watch-btn" onClick={handleShow}>
          Смотреть ролик
        </button>
  
        <Modal show={show} onHide={handleClose} size="lg">
        
            <div class="overview__video">
                <iframe class="overview__video-frame" width="100%" height="400px" src="https://www.youtube.com/embed/rkmO7lfwLeg" title="Видео о банных чанах в Екатеринбурге" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe><span class="overview__video-time">Продолжительность 0 мин. 50 сек.</span>
            </div>
         
        </Modal>
      </>
    );
  }