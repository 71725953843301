import React, { useState } from 'react';

import Lightbox from 'fslightbox-react';

// Images imports 
import PicOne from "../../../images/content/gallery/chanone-01.jpg"
import PicTwo from "../../../images/content/gallery/chanone-02.jpg"
import PicThree from "../../../images/content/gallery/chanone-03.jpg"
import PicO from "../../../images/content/gallery/chanone.jpg"
import PicFour from "../../../images/content/gallery/chanone-04.jpg"
import PicFive from "../../../images/content/gallery/chanone-05.jpg"
import PicSix from "../../../images/content/gallery/chanone-06.jpg"

const GalleryBlock = () => {

  const [toggler, setToggler] = useState(false);

    return (
        <>
        <div id="galereya"></div>
        <div class="gallery">
          <div class="container">
            <div class="gallery__wrap">
              <div class="gallery__side">
                <p class="section-title section-title--green gallery__title">БАНЯ ЛЕЧИТ НЕ ТОЛЬКО ТЕЛО НО И ДУШУ</p>
                <div class="gallery__row gallery__row--mobile"><button class="gallery-img gallery-img--flex"  onClick={() => setToggler(!toggler)} >
                    <div class="gallery-img__inner">
                      {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                        <g>
                          <g></g>
                          <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                        </g>
                      </svg> */}
                    </div><img class="gallery-img__pic" src={PicOne} alt="" /></button>
                  <div class="gallery-img__wrap"><button class="gallery-img gallery-img--small gallery-img--margin" onClick={() => setToggler(!toggler)}>
                      <div class="gallery-img__inner">
                        {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                          <g>
                            <g></g>
                            <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                        S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                        c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                        c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                          </g>
                        </svg> */}
                      </div><img class="gallery-img__pic" src={PicTwo} alt="" /></button><button class="gallery-img gallery-img--small gallery-img--margin" onClick={() => setToggler(!toggler)}>
                      <div class="gallery-img__inner">
                        {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                          <g>
                            <g></g>
                            <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                        S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                        c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                        c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                          </g>
                        </svg> */}
                      </div><img class="gallery-img__pic" src={PicThree} alt="" /></button></div>
                </div>
              </div>
              <div class="gallery__side">
                <div class="gallery__row gallery__row--flex"><button class="gallery-img gallery-img--flex" onClick={() => setToggler(!toggler)}>
                    <div class="gallery-img__inner">
                      {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                        <g>
                          <g></g>
                          <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                        </g>
                      </svg> */}
                    </div><img class="gallery-img__pic" src={PicO} alt="" /></button></div>
                <div class="gallery__row gallery-img__row"><button class="gallery-img gallery-img--small" onClick={() => setToggler(!toggler)}>
                    <div class="gallery-img__inner">
                      {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                        <g>
                          <g></g>
                          <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                        </g>
                      </svg> */}
                    </div><img class="gallery-img__pic" src={PicFour} alt="" /></button><button class="gallery-img gallery-img--small" onClick={() => setToggler(!toggler)}>
                    <div class="gallery-img__inner">
                      {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                        <g>
                          <g></g>
                          <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                        </g>
                      </svg> */}
                    </div><img class="gallery-img__pic" src={PicFive} alt="" /></button><button class="gallery-img gallery-img--small" onClick={() => setToggler(!toggler)}>
                    <div class="gallery-img__inner">
                      {/* <svg class="gallery-img__icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
                        <g>
                          <g></g>
                          <path d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667                                    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732                                    c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667                                    c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z"></path>
                        </g>
                      </svg> */}
                    </div><img class="gallery-img__pic" src={PicSix} alt="" /></button></div>
              </div>
            </div>

            <Lightbox
              toggler={toggler}
              sources={[
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-01.jpg',
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-02.jpg',
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-03.jpg',
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-00.jpg',
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-04.jpg',
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-05.jpg',
              'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/gallery/chanone-06.jpg',
              ]}
            />


            <button class="all-btn" type="button" aria-label="End of block"><span class="all-btn__line"></span></button>
          </div>
        </div>
      </>
    )
}

export default GalleryBlock

