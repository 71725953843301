import React from 'react';


// Images imports 
// import ArrowLeft from "../../../images/content/"

const NeedBlock = () => {
  
    return (
      <>
        
      <div class="need">
        <div class="need__left">
          <h2 class="section-title need__title">Для чего вам необходим банный чан</h2>
        </div>
        <div class="need__content">
          <div class="need__content-wrap">
            <div class="need__item">
              <div class="need__info need__info--hide">
                <div class="need__info-wrap"><span class="need__info-title">Для отдыха</span>
                  <p class="need__info-desc">Термический перепад температуры разгоняет кровь и способствует укреплению иммунитета, это особенно актуально сейчас, когда мир охвачен пандемией COVID-19.Будьте здоровы!</p>
                  <button class="need__info-close" type="button" aria-label="Show text block"><img src="images/content/arrow-left.png" alt="" /></button>
                </div>
              </div>
              <div class="need__block"><span class="need__block-title">Для отдыха</span>
                <p class="need__block-desc">Банный отдых на открытом воздухе. Незабываемое удовольствие. Попробуйте разок и вы обязательно захотите поставить себе чан.</p>
                <div class="need__info need__info--mobile">
                  <div class="need__info-wrap">
                    <p class="need__info-desc">Термический перепад температуры разгоняет кровь и способствует укреплению иммунитета, это особенно актуально сейчас, когда мир охвачен пандемией COVID-19.Будьте здоровы!</p>
                  </div>
                </div>
                <button class="need__block-btn" type="button">Читать подробнее</button>
              </div>
            </div>
            <div class="need__item">
              <div class="need__info need__info--hide">
                <div class="need__info-wrap"><span class="need__info-title">Для здоровья</span>
                  <p class="need__info-desc">Вашего и всех Ваших близких. Мы заботимся о качестве нашего чана, а Вы с его помощью позаботитесь о здоровье Вашей семьи. Привыкание организма к перепадам температуры происходит постепенно, однако уже после 10-ти процедур у детей укрепляется иммунитет, они заметно реже болеют!</p>
                  <button class="need__info-close" type="button" aria-label="Show text block"><img src="images/content/arrow-left.png" alt="" /></button>
                </div>
              </div>
              <div class="need__block"><span class="need__block-title">Для здоровья</span>
                <p class="need__block-desc">Укрепит иммунитет всей семьи.</p>
                <div class="need__info need__info--mobile">
                  <div class="need__info-wrap">
                    <p class="need__info-desc">Вашего и всех Ваших близких. Мы заботимся о качестве нашего чана, а Вы с его помощью позаботитесь о здоровье Вашей семьи. Привыкание организма к перепадам температуры происходит постепенно, однако уже после 10-ти процедур у детей укрепляется иммунитет, они заметно реже болеют!</p>
                  </div>
                </div>
                <button class="need__block-btn" type="button">Читать подробнее</button>
              </div>
            </div>
            <div class="need__item">
              <div class="need__info need__info--hide">
                <div class="need__info-wrap"><span class="need__info-title">СПА-уход за телом</span>
                  <p class="need__info-desc">Женщины особенно любят процедуры омоложения в банном чане. Это многократно проверенный способ сделать кожу мягкой, тонкой и здоровой. В старину не было столько косметики, как сейчас, и у женщин был небогатый выбор средств ухода за кожей. Но годами испытанное средство - понежится в бане - всегда делало женщину красивее и здоровее. Вкупе со СПА-релаксом, наш банный чан будет любимым местом времяпрепровождения.</p>
                  <button class="need__info-close" type="button" aria-label="Show text block"><img src="images/content/arrow-left.png" alt="" /></button>
                </div>
              </div>
              <div class="need__block"><span class="need__block-title">СПА-уход за телом</span>
                <p class="need__block-desc">Ваша женщина будет вам признательна. Узнайте из статьи о преимуществах СПА-чана</p>
                <div class="need__info need__info--mobile">
                  <div class="need__info-wrap">
                    <p class="need__info-desc">Женщины особенно любят процедуры омоложения в банном чане. Это многократно проверенный способ сделать кожу мягкой, тонкой и здоровой. В старину не было столько косметики, как сейчас, и у женщин был небогатый выбор средств ухода за кожей. Но годами испытанное средство - понежится в бане - всегда делало женщину красивее и здоровее. Вкупе со СПА-релаксом, наш банный чан будет любимым местом времяпрепровождения.</p>
                  </div>
                </div>
                <button class="need__block-btn" type="button" >Читать подробнее</button>
              </div>
            </div>
            <div class="need__item">
              <div class="need__info need__info--hide">
                <div class="need__info-wrap"><span class="need__info-title">У Вас отель или вы владелец сауны?</span>
                  <p class="need__info-desc">Тогда приобрести банный чан - Ваша насущная задача! Ведь он сделает пребывание в Вашем заведении комфортнее, приятнее и интереснее. Разве не для этого мы стараемся?</p>
                  <button class="need__info-close" type="button" aria-label="Show text block"><img src="images/content/arrow-left.png" alt="" /></button>
                </div>
              </div>
              <div class="need__block"><span class="need__block-title">У Вас отель или вы владелец сауны?</span>
                <p class="need__block-desc">Почитайте как вы можете создать дополнительный комфорт Вашим гостям, привлечь новых и сделать Ваш бизнес лучше и эффективней</p>
                <div class="need__info need__info--mobile">
                  <div class="need__info-wrap">
                    <p class="need__info-desc">Тогда приобрести банный чан - Ваша насущная задача! Ведь он сделает пребывание в Вашем заведении комфортнее, приятнее и интереснее. Разве не для этого мы стараемся?</p>
                  </div>
                </div>
                <button class="need__block-btn" type="button">Читать подробнее</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

export default NeedBlock;