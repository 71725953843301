import React from 'react';

import HeaderMenu from "../header"

// Images imports 
import ChanMain from "../../../images/content/chan.png"
import AdvIcon1 from "../../../images/content/adv-icon1.png"
import AdvIcon2 from "../../../images/content/adv-icon2.png"
import AdvIcon3 from "../../../images/content/adv-icon3.png"
import AdvIcon4 from "../../../images/content/adv-icon4.png"

const HeroBlock = () => {
    return (
        <>
            <div className="hero">
                <div className="container">
                    <div className="hero__wrap">
                        <HeaderMenu />
                        <div className="hero__content" >
                            <h2 className="hero__title"><span className="hero__title hero__title--first">Ист<span className="hero__title hero__title--broom">о</span>чник</span> <span className="hero__title hero__title--second">молод<span class="hero__title hero__title--broom-mobile">о</span>сти</span></h2>
                            <p className="hero__desc">у вас дома-банный чан от производителя</p>
                            
                            <div className="hero__img"><img class="hero__img-pic" src={ChanMain} alt="" /></div>
                            
                            
                        </div>
                        <div  className="advantages">
                            <div className="advantages-prev">
                                <div className="advantages-prev__img">
                                    <img className="advantages-prev__img-pic" src={AdvIcon1} alt="" />
                                </div>
                                    <p className="advantages-prev__desc">Гарантия на <br/>каждую деталь</p>
                            </div>

                            <div className="advantages-prev">
                                <div className="advantages-prev__img">
                                    <img className="advantages-prev__img-pic" src={AdvIcon2} alt="" />
                                </div>
                                    <p className="advantages-prev__desc">Только качественные <br/>материалы</p>
                            </div>

                            <div className="advantages-prev">
                            <div className="advantages-prev__img">
                                <img className="advantages-prev__img-pic" src={AdvIcon3} alt="" />
                            </div>
                                <p className="advantages-prev__desc">Уникальная собственная <br/>конструкция</p>
                            </div>

                            <div className="advantages-prev">
                           
                                <div className="advantages-prev__img">
                                    <img className="advantages-prev__img-pic" src={AdvIcon4} alt="Лучший банный чан в Екатеринбурге!" />
                                </div>
                          
                                <p className="advantages-prev__desc">Доставка в <br/>любую точку</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </>
    )
}

export default HeroBlock

