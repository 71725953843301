import React from 'react';
import EquipmentBlock from './equipmentblock';


const Equipment = () => {
    return (
        <EquipmentBlock />
    )
}

export default Equipment