import React from 'react';
import VideoModal from "./videomoddal";


// Images imports 

const OverviewBlock = () => {
    return (
        <>
        <div id="ochane"></div>
            <div class="overview">
                <div class="container">
                    <div class="overview__content">
                    <div class="overview__info"><span class="overview__title">Узнайте подробнее о конструкции чана из видео</span>
                        <p class="overview__desc">Видеообзор нашего чана особенности конструкции, монтажа и эксплуатации чана.</p>
                        
                        <div class="overview__info-footer"><VideoModal /></div>
                    </div>
                    <div class="overview__video">
                        <iframe class="overview__video-frame" width="595" height="333" src="https://www.youtube.com/embed/rkmO7lfwLeg" title="Видео о банных чанах в Екатеринбурге" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe><span class="overview__video-time">Продолжительность 0 мин. 50 сек.</span>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OverviewBlock


