import React from 'react';
import ProjectsBlock from './projectsblock';


const Projects = () => {
    return (
        <ProjectsBlock />
    )
}

export default Projects