import React from 'react';
import NeedBlock from './needblock';


const Need = () => {
    return (
        <NeedBlock />
    )
}

export default Need