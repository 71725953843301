import React from 'react';
import CatalogBlock from './catalogblock';


const Catalog = () => {
    return (
        <CatalogBlock />
    )
}

export default Catalog