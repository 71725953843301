import React, { Component } from "react";
import Slider from "react-slick";

// Images imports 
import Podsvetka from "../../../images/content/podsvetka.jpg"
import Jacuzzi from "../../../images/content/jacuzzi.jpg"
import Trenoga from "../../../images/content/trenoga.jpg"
// import Tapki from "../../../images/content/tapki.jpg"
import Shapki from "../../../images/content/shapki.jpg"

export default class EquipmentSlider extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ],
      customPaging: i => (
            <div class="slider-nav">
                <div class="reviews-button-prev swiper-button-prev slider-nav__btn slider-nav__btn-prev">
                    {/* <svg сlass="slider-nav__icon" width="48" fill="rgba(170, 170, 170, 0.83)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                    <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                         405.606,308.713 476.213,238.106 "></polygon>
                    </svg> */}
                </div>
                <div class="equipment-pagination swiper-pagination slider-pagination">{'0'+ (i + 1)}</div>
                <div class="reviews-button-next swiper-button-next slider-nav__btn slider-nav__btn-next">
                    {/* <svg сlass="slider-nav__icon" width="48" fill="rgba(170, 170, 170, 0.83)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewbox="0 0 476.213 476.213" style="enable-background:new 0 0 476.213 476.213;" xml:space="preserve">
                    <polygon points="405.606,167.5 384.394,188.713 418.787,223.106 0,223.106 0,253.106 418.787,253.106 384.394,287.5                         405.606,308.713 476.213,238.106 "></polygon>
                    </svg> */}
                </div>
            </div>
      )
    };
    return (
      <div class="equipment">
      <div class="container">
        <div class="equipment__title">ДОПОЛНИТЕЛЬНАЯ КОМПЛЕКТАЦИЯ</div>
        <Slider {...settings}>
            {/* SLIDER ONE */}
            <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>

                  <div class="equipment-prev">
                    <div class="equipment-prev__img"><img class="equipment-prev__img-pic" src={Podsvetka} alt= "" /></div>
                    <div class="equipment-prev__info"><span class="equipment-prev__title">Подсветка чана</span>
                    <div><h6>Цена:<p><strong>35 000 рублей</strong></p></h6></div>
                      <div class="equipment-prev__list"><span class="equipment-prev__list-item">Потребление 12вольт;</span><span class="equipment-prev__list-item">Влагозащищенный радиопульт;</span><span class="equipment-prev__list-item">Влагозащищенный корпус щита управления;</span><span class="equipment-prev__list-item">Подсветка создаст классную атмосферу!</span></div>
                      <button class="btn-chan btn-chan--orange equipment-prev__add" type="button"><a href="/catalog/podsvetka">Подробнее</a></button>
                    </div>
                  </div>
            </div>

            <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>

              <div class="equipment-prev">
                <div class="equipment-prev__img"><img class="equipment-prev__img-pic" src={Jacuzzi} alt= "" /></div>
                <div class="equipment-prev__info"><span class="equipment-prev__title">"Джакузи" на чан</span>
                <div><h6>Цена:<p><strong>от 71 000 рублей</strong></p></h6></div>
                  <div class="equipment-prev__list"><span class="equipment-prev__list-item">Система подачи волшебных пузырьков;</span><span class="equipment-prev__list-item">Расслабляет</span></div>
                  <button class="btn-chan btn-chan--orange equipment-prev__add" type="button"><a href="/catalog/jakuzzi">Подробнее</a></button>
                </div>
              </div>
            </div>

            <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>

              <div class="equipment-prev">
                <div class="equipment-prev__img"><img class="equipment-prev__img-pic" src={Trenoga} alt= "" /></div>
                <div class="equipment-prev__info"><span class="equipment-prev__title">Тренога на чан </span>
                <div><h6>Цена:<p><strong>от 30 000 рублей</strong></p></h6></div>
                  <div class="equipment-prev__list"><span class="equipment-prev__list-item">Необычная и привлекательная конструкция;</span><span class="equipment-prev__list-item">Отлично вписывается в экстерьер к бревенчатым домам</span></div>
                  <button class="btn-chan btn-chan--orange equipment-prev__add" type="button"><a href="/catalog/trenoga">Подробнее</a></button>
                </div>
              </div>
            </div>

          {/* <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>
          <div class="equipment-prev">
                    <div class="equipment-prev__img"><img class="equipment-prev__img-pic" src={Tapki} alt= "" /></div>
                    <div class="equipment-prev__info"><span class="equipment-prev__title">Тапочки и чуни <br/>для бани</span>
                    <div><h6>Цена:<p><strong>2500 рублей</strong></p></h6></div>
                      <div class="equipment-prev__list"><span class="equipment-prev__list-item">Натуральный мех, овчина.</span><span class="equipment-prev__list-item">Сохраняют естественное тепло ног</span><span class="equipment-prev__list-item">благодаря своим "дышащим" свойствам.</span><span class="equipment-prev__list-item">Способствуют правильной циркуляции </span><span class="equipment-prev__list-item">потока крови от ступней вверх.</span></div>
                      <button class="btn-chan btn-chan--orange equipment-prev__add" type="button">Подробнее </button>
                    </div>
                  </div>
          </div> */}

          <div style={{paddingLeft: '2vw', paddingRight: '2vw'}}>
          <div class="equipment-prev">
                    <div class="equipment-prev__img"><img class="equipment-prev__img-pic" src={Shapki} alt= "" /></div>
                    <div class="equipment-prev__info"><span class="equipment-prev__title">Шапки <br/>для бани</span>
                    <div><h6>Цена:<p><strong>2500 рублей</strong></p></h6></div>
                      <div class="equipment-prev__list"><span class="equipment-prev__list-item">Натуральный мех, овчина.</span></div>
                      <button class="btn-chan btn-chan--orange equipment-prev__add" type="button">Подробнее </button>
                    </div>
                  </div>
          </div>
        </Slider>
      </div>
      </div>
    );
  }
}