import React from 'react';


// Images imports 
import Chan2 from "../../../images/content/chan2.png"

const AboutBlock = () => {
    return (
        <>
       
       <div class="about">
          <div class="container">
            <div class="about__wrap">
              <h2 class="section-title about__title">Что такое банный чан</h2>
              <div class="about__info">
                <div class="about__img"><img class="about__img-pic" src={Chan2} alt="Купить банный чан в Екатеринбурге" /></div>
                <div class="about__info-list">
                  <div class="about__info-block">
                    <div class="about__item"><span class="about__item-circle"><span class="about__item-circle-inner"></span></span><span class="about__item-name">Качественная <br/>3 мм сталь  </span></div>
                    <div class="about__item about__item--w100"><span class="about__item-circle"><span class="about__item-circle-inner"></span></span><span class="about__item-name">Обшивка из <br/>первоклассной <br/>древесины </span></div>
                    <div class="about__item about__item--w120"><span class="about__item-circle"><span class="about__item-circle-inner"></span></span><span class="about__item-name">Гарантия на чашу <br/>10 лет</span></div>
                  </div>
                  <div class="about__info-block">
                    <div class="about__item about__item--w180"><span class="about__item-circle"><span class="about__item-circle-inner"></span></span><span class="about__item-name">Широкий выбор <br/>качественных дополнительных <br/>аксессуаров</span></div>
                    <div class="about__item about__item--w150"><span class="about__item-circle"><span class="about__item-circle-inner"></span></span><span class="about__item-name">Оздоровление и <br/>релаксация, <br/>поддержка иммунитета</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}

export default AboutBlock


