import * as React from "react"
import Seo from "../components/seo"
import Fade from 'react-reveal';

import "../styles/styles.scss"

// import Header from "../components/header"
import Hero from "../components/MainPage/hero"
// import Advantages from "../components/MainPage/advantages"
import About from "../components/MainPage/about"
import Overview from "../components/MainPage/overview"
import Need from "../components/MainPage/need"
import Gallery from "../components/MainPage/gallery"
import Production from "../components/MainPage/production"
import Catalog from "../components/MainPage/catalog"
import Equipment from "../components/MainPage/equipment"
import Process from "../components/MainPage/process"
import Reviews from "../components/MainPage/reviews"
import Offer from "../components/MainPage/offer"
import Promo from "../components/MainPage/promo"
import Projects from "../components/MainPage/projects"
import Contacts from "../components/MainPage/contact"
// import Feedback from "../components/MainPage/feedback"
import Footer from "../components/MainPage/footer"


const IndexPage = () => {
  return (
    <>
      <Seo 
        title="Купить банный чан от производителя в Екатеринбурге по доступной цене"
        description="Наша компания предлагает банный чан на дровах из нержавейки, стали, с подсветкой по цене производителя. Уличная купель – отличное решение для частного дома, дачи, отеля или СПА-центра. Ее можно использовать для парения, купания на свежем воздухе. Качество нашей продукции оценили множество пользователей"
        keywords = "Купить банный чан на дровах от производителя цена екатеринбург, купить уличный чан для купания и парения на воздухе, банный чан из стали нержавейки цена, банный чан с подсветкой цена, недорого, стоимость, уличный, купить, даче, подогревом, фото"
      />
      <main class="wrapper">
        <Fade>
        <div class="content">
          <Hero />
          {/* <Advantages /> */}
          <About />
          <Overview />
          <Need />
          <Gallery />
          <Production />
          <Catalog />
          <Equipment />
          <Process />
          <Reviews />
          <Offer />
          <Promo />
          <Projects />
          <Contacts />
          {/* <Feedback /> */}
          <Footer />

        </div> 
        </Fade>    
      </main>
    </>
  )
}

export default IndexPage
