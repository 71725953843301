import React, {useState} from 'react';
import {Fade} from "react-reveal"


// Images imports 
import ProcChan from "../../../images/content/chan3.png"

// const ProcessBlock = () => {
//   const [processtext, setText] = useState(<Text1 />)
//   const [tabclass, setTabClass] = useState("tabs-nav__item process__steps-item")

  const tabItems = [
    {
      id: 1,
      tab: <div><span class="process__steps-num">1</span><span class="process__steps-name">БРОНЬ</span></div>,
      icon: 'tabitem__icon fas fa-child',
      content:  <div class="process__offer-content">
                  <Fade duration={2000}>
                    <h5>ОСТАВЬТЕ ЗАЯВКУ</h5>
                    <p class="process__offer-desc">Заявку на изготовление чана можно оставить на сайте выбрав понравившийся вам чан и нажав кнопку заказать. Наш менеджер связывается с вами и обговаривает детали заказа. Другой способ заказа предполагает звонок по телефону <a class="process__phone" href="tel:79667076676">+ 7 966 707 66 76</a></p>
                  </Fade>
                </div>,
    },
    {
      id: 2,
      tab: <div><span class="process__steps-num">2</span><span class="process__steps-name">ОПЛАТА</span></div>,
      icon: 'tabitem__icon fas fa-users',
      content:  <div class="process__offer-content">
                  <Fade duration={2000}>
                    <h5>ОТПРАВЬТЕ ПРЕДОПЛАТУ</h5>
                    <p class="process__offer-desc">Предоплата составляет 50% от стоимости заказа. Для юридических лиц оплата производится безналичным переводом денежных средств по счету. Физические лица вносят предоплату с помощью онлайн-перевода на банковский счет Компании.</p>
                  </Fade>
                </div>,
    },
    {
      id: 3,
      tab: <div><span class="process__steps-num">3</span><span class="process__steps-name">СБОРКА</span></div>,
      icon: 'tabitem__icon fas fa-network-wired',
      content:  <div class="process__offer-content">
                  <Fade duration={2000}>
                    <h5>ВАШ БАННЫЙ ЧАН ИЗГОТАВЛИВАЕТСЯ</h5>
                    <p class="process__offer-desc">Банный чан изготавливается на нашем производстве в обговоренные заранее сроки, с учетом заявленной в заказе комплектации чана.</p>
                  </Fade>
                </div>,
    },
    {
      id: 4,
      tab: <div><span class="process__steps-num">4</span><span class="process__steps-name">ОПЛАТА</span></div>,
      icon: 'tabitem__icon fas fa-money-check-alt',
      content:  <div class="process__offer-content">
                  <Fade duration={2000}>
                    <h5>ОПЛАЧИВАЕТЕ ОСТАВШУЮСЯ СУММУ</h5>
                    <p class="process__offer-desc">После того, как банный чан изготовлен, Вы вносите оставшуюся сумму по договору.</p>
                  </Fade>
                </div>,
    },
    {
      id: 5,
      tab: <div><span class="process__steps-num">5</span><span class="process__steps-name">ДОСТАВКА</span></div>,
      icon: 'tabitem__icon fas fa-money-check-alt',
      content:  <div class="process__offer-content">
                  <Fade duration={2000}>
                    <h5>ПРИНИМАЕТЕ БАННЫЙ ЧАН ДОМА</h5>
                    <p class="process__offer-desc">Мы доставляем и устанавливаем чан в заранее подготовленном для него месте.</p>
                  </Fade>
                </div>,
    },
  ];

  const TabItemComponent = ({
    icon = '',
    tab = '',
    onItemClicked = () => console.error('You passed no action to the component'),
    isActive = false,
  }) => {
    return (
      <div className={isActive ? 'tabs-nav__item tabs-nav__item--active process__steps-item' : 'tabs-nav__item process__steps-item'} role="button" tabIndex="-1" onClick={onItemClicked} onKeyDown={onItemClicked}>
        <i className={icon}></i>
        {tab}
      </div>
    )
  };
  
  const ProcessBlock = () => {
   const [active, setActive] = useState(1);
    
    return (
      <div class="process">
           <div class="container">
             <div class="process__wrap">
               <div class="process__question">
                 <div class="process__question-img"><img class="process__question-pic" src={ProcChan} alt="" /><span class="process__question-char">?</span></div>
                 <p class="process__title">КОГДА БАННЫЙ ЧАН БУДЕТ НА МОЕМ УЧАСТКЕ?</p>
               </div>
      
              <div className="tabs process__content">
                <div className="tabs-nav process__steps">
                  {tabItems.map(({ id, icon, tab }) =><TabItemComponent
                    key={tab}
                    icon={icon}
                    tab={tab}
                    onItemClicked={() => setActive(id)}
                    isActive={active === id}
                  />
                )}
                </div>
                <div className="tabs-content__item tabs-content__item--active process__offer">
                <p class="process__offer-desc">
                  {tabItems.map(({ id, content }) => {
                    return active === id ? content : ''
                  })}
                  </p>
                </div>
              </div>
              
            </div>
          </div>
       </div>
    )
  }
  


//     return (
//         <>
//          <div class="process">
//           <div class="container">
//             <div class="process__wrap">
//               <div class="process__question">
//                 <div class="process__question-img"><img class="process__question-pic" src={ProcChan} alt="" /><span class="process__question-char">?</span></div>
//                 <p class="process__title">КОГДА БАННЫЙ ЧАН БУДЕТ НА МОЕМ УЧАСТКЕ?</p>
//               </div>
//               <div class="tabs process__content">
//                 <div class="tabs-nav process__steps">
//                   <div class={tabclass ? "tabs-nav__item tabs-nav__item--active process__steps-item" : "tabs-nav__item process__steps-item"} data-tab-name="tab1" onClick = {() => setTabClass("tabs-nav__item tabs-nav__item--active process__steps-item")}><span class="process__steps-num">1</span><span class="process__steps-name">БРОНЬ</span></div>
//                   <div class={tabclass ? "tabs-nav__item process__steps-item" : "tabs-nav__item tabs-nav__item--active process__steps-item"} data-tab-name="tab2" onClick = {() => setTabClass("tabs-nav__item tabs-nav__item--active process__steps-item")}><span class="process__steps-num">2</span><span class="process__steps-name">ОПЛАТА</span></div>
//                   <div class={tabclass ? "tabs-nav__item process__steps-item" : "tabs-nav__item tabs-nav__item--active process__steps-item"} data-tab-name="tab3" ><span class="process__steps-num">3</span><span class="process__steps-name">СБОРКА</span></div>
//                   <div class={tabclass ? "tabs-nav__item process__steps-item" : "tabs-nav__item tabs-nav__item--active process__steps-item"} data-tab-name="tab4"><span class="process__steps-num">4</span><span class="process__steps-name">ОПЛАТА</span></div>
//                   <div class={tabclass ? "tabs-nav__item process__steps-item" : "tabs-nav__item tabs-nav__item--active process__steps-item"} data-tab-name="tab5" ><span class="process__steps-num">5</span><span class="process__steps-name">ДОСТАВКА</span></div>
//                 </div>
//                 <div class="tabs-content">
//                   <div class="tabs-content__item tabs-content__item--active process__offer tab3">
//                     <div class="process__offer-content">
//                       {processtext}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     )
// }



export default ProcessBlock