import React from 'react';
import GalleryBlock from './galleryblock';


const Gallery = () => {
    return (
        <GalleryBlock />
    )
}

export default Gallery