import React, { Component } from "react";
import Slider from "react-slick";

import "../../../../node_modules/slick-carousel/slick/slick.css";

const PREFIX_URL = 'https://raw.githubusercontent.com/surreum/imagestore/main/chanone/prod';


export default class CenterMode extends Component {
  render() {
    const settings = {
      customPaging: function(i) {
        return (
          <div class="swiper-container production-tumbs">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="gallery-img gallery-img--small production-tumbs__item">
                  <div class="gallery-img__inner"><span class="production__plus"></span></div>
                  
                  <img className="gallery-img__pic" src={`${PREFIX_URL}/prod-0${i + 1}.jpg`} style={{display: 'inline-block',}} alt= "" />
    
                </div>
              </div>
            </div>
          </div>
        );
      },
      dots: true,
      arrows: false,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          {/* FIRST SLIDE  */}
          <div class="gallery__side production__block">
            <div class="gallery__row gallery__row--flex">
              <div class="gallery-img gallery-img--flex production__img">
                <img src={PREFIX_URL + "/prod-01.jpg"} className="gallery-img__pic"  alt= "" />
                <div class="production__img-info"><span class="production__img-title">Сварка конструкции</span>
                <p class="production__img-desc">Сварка каркаса и деталей банного чана - один из ответственных этапов производства. При нагревании металл расширяется и каждый сварной шов должен гарантированно выдержать свою нагрузку. </p>
                </div>
              </div>
            </div>
          </div>

          {/* SECOND SLIDE  */}
          <div class="gallery__side production__block">
            <div class="gallery__row gallery__row--flex">
              <div class="gallery-img gallery-img--flex production__img">
                <img src={PREFIX_URL + "/prod-02.jpg"} className="gallery-img__pic"  alt= "" />
                <div class="production__img-info"><span class="production__img-title">Раскройка металла</span>
                <p class="production__img-desc">Раскройка металла осуществляется на современном лазерном оборудовании, исключая дефекты отдельных деталей, гарантируя надежность сваривания деталей между собой. </p>
                </div>
              </div>
            </div>
          </div>

          {/* THIRD SLIDE  */}
          <div class="gallery__side production__block">
            <div class="gallery__row gallery__row--flex">
              <div class="gallery-img gallery-img--flex production__img">
                <img src={PREFIX_URL + "/prod-03.jpg"} className="gallery-img__pic"  alt= "" />
                <div class="production__img-info"><span class="production__img-title">Шлифовка, подготовка под покраску</span>
                <p class="production__img-desc">Банный чан создан для удовольствия, поэтому каждый миллиметр должен быть тщательнейшим образом отшлифован. </p>
                </div>
              </div>
            </div>
          </div>

        </Slider>
      </div>
    );
  }
}