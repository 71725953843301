import React from 'react';
import OfferBlock from './offerblock';


const Offer = () => {
    return (
        <OfferBlock />
    )
}

export default Offer