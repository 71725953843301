import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import MainOfferBlockForm from './MainOfferBlockForm'


const MainOfferForm = () => {
    return(
        <Container>
            <Row>
                <Col>
                    <MainOfferBlockForm />
                </Col>
            </Row>
        </Container>
    )
}

export default MainOfferForm