import React from 'react';
import ReviewsSlider from './reviewsslider'


const Reviews = () => {
    return (
        <ReviewsSlider />
    )
}

export default Reviews