import React from 'react';
import ProductionBlock from './productionblock';


const Production = () => {
    return (
        <ProductionBlock />
    )
}

export default Production