import React from 'react';

// Images imports 

const PromoBlock = () => {
    return (
        <>
            <div className="promo">
                <div className="container">
                    <p className="promo__title">А ЕЩЕ МЫ ДЕЛАЕМ Крутые дома и бани из большого бревна</p>
                </div>
            </div>
      </>
    )
}

export default PromoBlock